import * as React from "react" 
import "../styles/components/spinner.scss"

const Spinner = ({size, color}) => {
    return (
            <div className={`spinner relative w-fit`}>
                <div className="centering-container h-fit- w-fit">
                    <div className={`parent-container after:bg-primary-800 scale-[${ size === "xs" ? "0.3" : size === "sm" ? "0.35" : size === "lg" ? "0.5" : "1"}]`}>
                        <div className={`parent border-${color} border-primary-800 `}>
                            <div className={`child border-${`primary-800`}`}></div>
                            <div className={`child border-${`primary-800`}`}></div>
                            <div className={`child border-${`primary-800`}`}></div>
                            <div className={`child border-${`primary-800`}`}></div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default Spinner