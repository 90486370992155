import "../styles/components/about.scss"

import * as React from "react"

import { BsChevronCompactDown } from "react-icons/bs"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image";
import eventBus from "../components/bus";
import { graphql } from "gatsby";

function debounce(func, timeout = 300){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

function setIntersectionObserver(handler, appertureRef) {

  const height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
  const width = window.innerWidth|| document.documentElement.clientWidth|| document.body.clientWidth;

  if(width < 769) {
    return new IntersectionObserver(handler,{threshold:1,rootMargin:`0px 0px -${height/3}px 0px `})
  } else {
    if(appertureRef.current !== null) {
      return new IntersectionObserver(handler,{threshold:1, rootMargin:`0px 0px -${(height - appertureRef.current.clientHeight)/3}px 0px`})
    }
    
  }

}

const IndexPage = ({data}) => {

  data = data.site.siteMetadata

  const appertureRef = React.useRef(null) 
  const homeRef = React.useRef(null)
  const [inView, setInView] = React.useState(false)

  React.useEffect(() => {

    let observer ; 
    let apperture = appertureRef.current
  
    let previousY = 0
    let previousRatio = 0

    const oberserverHanlder = ([entry]) => {

      const currentY = entry.boundingClientRect.y
      const currentRatio = entry.intersectionRatio
      const isIntersecting = entry.isIntersecting
  
      if (currentY < previousY) {
        if (currentRatio > previousRatio && isIntersecting) {
          // "Scrolling down enter"
          console.log("Scrolling down enter")
          setInView(true)
        } else {
          console.log("Scrolling down leave")
        }
      } else if (currentY > previousY) {
        if (currentRatio < previousRatio) {
          //state.textContent ="Scrolling up leave"
          if(isIntersecting) {
            //"Scrolling up enter"]
            console.log("Scrolling up enter")
            setInView(true)
          } else {
            // "Scrolling up leave"
            console.log("Scrolling up leave")
            setInView(false)
          }
          
        } else {
          console.log("Scrolling up enter else")
          //state.textContent ="Scrolling up enter"
          if(isIntersecting) setInView(true)
          
        }
      }

      previousY = currentY
      previousRatio = currentRatio
    }

    if(apperture) {
      observer = setIntersectionObserver.bind(this)(oberserverHanlder, appertureRef)
      observer.observe(apperture)
    }

    const homeObserver = new IntersectionObserver(([entry]) => {
      if(!entry.isIntersecting) {
        eventBus.dispatch("AddBGToHeader")
      } else {
        eventBus.dispatch("RemoveBGToHeader")
      }
    }, {threshold:0.01,rootMargin:'0px 0px 10px 0px'})

    if(homeRef.current) {
      homeObserver.observe(homeRef.current)
    }

    if(typeof(window) !== 'undefined' && typeof(document) !== 'undefined') {
      window.addEventListener('resize', debounce(() => {
        if(apperture) {
          if (observer) observer.unobserve(apperture); 
          observer = setIntersectionObserver.bind(this)(oberserverHanlder,appertureRef)
          observer.observe(apperture)
        }
      },200))
    }

    return () => {
      if(apperture) {
        observer.unobserve(apperture)
      }
      
    }
  },[])


  return (
    <>
      <Seo 
        description="I'm Aaqil Abdul Rahman, a Director of Photography & Colorist based in Mumbai & Manipal. I'm constantly looking for new & exciting projects to work on." 
        page="index"
      />
      <Layout>
        <div className="w-full min-h-screen-fill-content into relative scroll-smooth" ref={homeRef}>
          <div className="h-full w-full absolute flex underlay z-[7] flex-row overflow-hidden">
            <div className="h-full w-full absolute bg-primary/60 z-[5]"></div>
            <div className="flex flex-1 h-full z-1">
              <StaticImage
                src="../images/index.jpg"
                className="h-full w-full"
                loading="eager"
                objectPosition="cover"
                alt="Me, Myself and I"
              />
            </div>
          </div>
          <div className="z-10 font-playfair font-black text-primary-100 flex flex-col uppercase -translate-y-1/2 pl-6 md-pl-0 md:left-[5%] top-1/2 translate-x-0 absolute">
            <span className="text-lg font-bold tracking-wider">Hi! I am</span>
            <h1 className="text-3xl sm:text-4xl md:text-4xl lg:text-5xl tracking-wide text-pop mb-0">Aaqil</h1>
            <h1 className="text-3xl sm:text-4xl md:text-4xl lg:text-5xl tracking-wide text-pop mb-0">Abdul Rahman</h1>
            <span className="text-lg font-bold tracking-wider">I'm a</span>
            <h2 className="text-3xl sm:text-4xl md:text-4xl lg:text-5xl tracking-wide text-pop">{data.title}</h2>
            <span className="text-lg font-bold tracking-wider">based in</span>
            <h2 className="text-3xl sm:text-4xl md:text-4xl lg:text-5xl tracking-wide text-pop">{data.locationCity},</h2>
            <h2 className="text-3xl sm:text-4xl md:text-4xl lg:text-5xl tracking-wide text-pop">{data.locationCountry}</h2>
          </div>
          <div className="z-10 flex flex-col text-primary-100 lg:transition-[color] lg:duration-300 lg:hover:text-pop-secondary navigator font-playfair uppercase justify-center items-center font-black absolute left-1/2 -translate-x-1/2 text-sm md:text-base bottom-3">
            <span className="mx-2 text"><a href="#about" className="no-underline text-inherit tracking-wide font-bold">About</a></span>
            <BsChevronCompactDown className="arrow scale-150"/>
          </div>
        </div>
        <div className="w-full min-h-screen-fill-content about flex flex-col md:justify-center md:items-center pt-20 md:p-0 md:py-20" id="about">
          <div className="w-full md:w-3/4 flex items-center justify-center">
            <div ref={appertureRef} className="mb-5 relative h-40 w-40 xs:h-48 xs:w-48 sm:h-64 sm:mb-0 sm:w-64 lg:h-96 lg:w-96 z-[50] rounded-[100%] overflow-hidden">
              <StaticImage
                src="../images/potrait.jpg"
                layout="constrained"
                className="absolute h-full w-full"
                alt={data.name}
              />
              <div className={`apperture-container absolute w-full h-full top-0 overflow-hidden rounded-full scale-[3] sm:scale-[3] md:scale-[2.6] ${inView ? `in-view` : ``}`}>
                <div className="triangle after:border-b-[111] border-b-primary-900 z-[100]"></div>
                <div className="triangle border-b-primary-900 z-[30]"></div>
                <div className="triangle border-b-primary-900 z-[30]"></div>
                <div className="triangle border-b-primary-900 z-[30]"></div>
                <div className="triangle border-b-primary-900 z-[30]"></div>
                <div className="triangle border-b-primary-900 z-[30]"></div>
                <div className="apperture-border-overlay absolute h-full w-full -z-10 duration-[.7s] bg-primary-500 transition-[opacity]">

                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-3/4 flex px-6 lg:px-10 pb-6 lg:pb-10 font-raleway flex-col text-left  text-primary-100">
            <div className="w-full pb-0 mx-auto desc-text md:pt-20" dangerouslySetInnerHTML={{__html:data.pageData.home.aboutFirstHalf}}>
            </div>
            <StaticImage
                src="../images/about.png"
                objectFit="contain"
                className="h-64 sm:h-96 lg:h-[36rem] py-5 md:py-0"
                alt={`My Dad & I`}
            />
            <div className="w-full mx-auto desc-text" dangerouslySetInnerHTML={{__html:data.pageData.home.aboutSecondHalf}}>
            </div>
          </div>
        </div>
      </Layout>
    </>

  )
 ; 
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        pageData {
          home {
            aboutFirstHalf
            aboutSecondHalf
          }
        }
        name
        title
        locationCity
        locationCountry
      }
    }
  }
`


export default IndexPage
