import "../styles/pages/contact.scss"

import * as React from "react"

import { AiOutlineLinkedin, AiOutlineYoutube } from "react-icons/ai"

import { BsInstagram } from "react-icons/bs"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const ContactPage = ({data}) => {

  const pageData = data.site.siteMetadata.pageData.contact
  const socials = data.site.siteMetadata.socials

  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [formSuccess, setFormSuccess] = React.useState(null)

  const [fromEmail,setFromEmail] = React.useState('')
  const [name,setName] = React.useState('')
  const [content,setContent] = React.useState('')

  const nameRef = React.createRef(false)
  const emailRef = React.createRef(false)
  const subjectRef = React.createRef(false) 
  const bodyRef = React.createRef(false)

  if(typeof(window) !== 'undefined') {
    window.onSubmit = async () => {
      const data = {
        email: fromEmail,
        name,
        content
      }

      try {
        const req = await fetch(`${process.env.GATSBY_FNS_ENDPOINT}/email`, {
          method:`POST`,
          headers:{
            'Authorization':`Bearer ${process.env.GATSBY_SITE_KEY}`
          },
          body:JSON.stringify(data)
        })
  
        console.log(req.ok,await req.text())
  
        window.setTimeout(() => setFormSuccess(req.ok),1000) 
      } catch(error) {
        window.setTimeout(() => setFormSuccess(false),1000) 
      }



      
    }
    window.onRecaptchaFailure = () => window.setTimeout(() => {setFormSuccess(false)},1000)
  }

  const runRecaptcha = (e) => {
    e.preventDefault()

    if(emailRef.current && !emailRef.current.validity.valid) {
      console.log(emailRef, emailRef.current.validity)
      return ; 
    }

    if(subjectRef.current && !subjectRef.current.validity.valid) {
      console.log(subjectRef, subjectRef.current.validity)
      return ; 
    }

    if(bodyRef.current && !bodyRef.current.validity.valid) {
      console.log(bodyRef, bodyRef.current.validity)
      return ; 
    }

    setFormSubmitted(true)
    
    if(typeof window !== 'undefined' && window.grecaptcha) {
      try {
        window.grecaptcha.execute()
      } catch (error) {
        console.log(error)
        window.setTimeout(() => setFormSuccess(false),1000) 
      }
      
    } 
  }

  return (
    <>
      <Layout>
        <Seo title="Contact" page="contact" description="If you'd like to talk about an exciting project or would like to work with me, feel free to contact me"/>
        <Helmet>
          <script async src="https://www.google.com/recaptcha/api.js"></script>
        </Helmet>
        <div className="w-full">
          <div className="max-w-screen-2xl mx-auto min-h-screen-fill-content flex flex-col lg:flex-row pt-20">
            <div className="email flex items-center p-6 lg:flex-[6] lg:pr-0 relative">
              <div className="relative w-full h-full flex items-center">
                <div className={`form w-full lg:w-2/3 mx-auto ${formSuccess !== null ? `form-processed` : ``}`}>
                  <div className="header">
                    <div className="font-playfair text-3xl lg:text-4xl lg:text-5xl uppercase text-center font-black text-pop">
                      Say Hi.
                    </div>
                    <p className="mt-6 text-primary-100 desc-text">
                      {pageData.text}
                    </p>
                  </div>
                  <div className="wrapper">
                    <form>
                    <div className="form-container flex flex-col text-primary-100">
                      <input ref={nameRef} className={
                        `w-full p-3 border font-raleway focus:outline-none focus:border-primary-100 transition-[border-color] bg-transparent border-primary-700 duration-300 hover:border-primary-100`
                      } type="name" required placeholder="Name *" onChange={(event) => setName(event.target.value)}/>
                      <input ref={emailRef} className={
                        `w-full p-3 border font-raleway mt-3 focus:outline-none focus:border-primary-100 transition-[border-color] bg-transparent border-primary-700 duration-300 hover:border-primary-100`
                      } type="email" required placeholder="Email *" onChange={(event) => setFromEmail(event.target.value)}/>
                      <textarea ref={bodyRef} onChange={(event) => setContent(event.target.value)} className="border focus:outline-none focus:border-primary-100 p-3 mt-3 min-h-[9rem] max-h-[12rem] font-raleway transition-[border-color] bg-transparent border-primary-700 duration-300 hover:border-primary-100" placeholder="Content *" required></textarea>
                      <div className="g-recaptcha hidden"
                        data-sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
                        data-callback="onSubmit"
                        data-error-callback="onRecaptchaFailure"
                        data-size="invisible"
                        data-badge="inline"
                      >
                      </div>
                      <button className={
                        `submit-form mt-3 relative font-playfair font-bold p-3 border text-primary-100 ` + 
                        `lg:transition-[border-color, color] bg-transparent border-primary-700 lg:duration-300 ` +
                        `lg:hover:border-pop-secondary lg:hover:text-pop-secondary ${formSubmitted ? `border-pop-secondary text-pop-secondary submitted` : ``}`
                      } onClick={runRecaptcha} type="submit">
                        <span className="block relative invisible">{formSubmitted ? `Sending ...` : `Send`}</span>
                      </button>
                    </div>
                    </form>
                    
                  </div>
                </div>
                <div className={`server-response -z-10 w-full lg:w-2/3 mx-auto absolute left-[50%] -translate-x-[50%] ${formSuccess !== null ? `form-processed` : ``}`}>
                  <div className={`header ${formSuccess === true ? `block` : `hidden`}`}>
                    <div className="font-playfair text-3xl lg:text-4xl lg:text-5xl uppercase text-center font-black text-pop">
                      Thanks.
                    </div>
                    <p className="font-raleway font-medium mt-6 text-primary-100 leading-loose text-center w-full block max-w-full">
                      Thanks for reaching out to me! You'll be hearing from me shortly.
                    </p>
                  </div>
                  <div className={`header ${formSuccess === true ? `hidden` : `block`}`}>
                    <div className="font-playfair text-3xl lg:text-4xl lg:text-5xl uppercase text-center font-black text-red-error">
                      Oops.
                    </div>
                    <p className="font-raleway font-medium mt-6 text-primary-100 leading-loose text-center w-full block max-w-full">
                      Looks like there was an issue while trying to process your message. Please try again later.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="others flex flex-col-reverse  flex-1 lg:flex lg:flex-col lg:flex-[4] pt-0 sm:pt-6 p-6 lg:pl-0 lg:pt-0">
              <div className="location overflow-hidden p-6 relative h-72 sm:h-96 lg:h-full flex flex-col lg:h-inherit justify-evenly items-center text-center relative">
                <div className="socials flex flex-col items-center relative z-[15] justify-center text-primary-100">
  
                  <div className="icons flex">
                    <a href={socials.ig} className="text-inherit" target="_blank" rel="noreferrer">
                      <BsInstagram className="text-lg sm:text-xl xl:text-2xl lg:hover:text-pop-secondary cursor-pointer lg:duration-300 lg:transition-[color]"/>
                    </a>
                    <a href={socials.linkedin} className="text-inherit" target="_blank" rel="noreferrer">
                      <AiOutlineLinkedin className="text-xl sm:text-2xl xl:text-3xl mx-8 md:mx-10 lg:mx-12 xl:mx-16 relative -top-[1px] lg:-top-[1px] xl:-top-[3px] lg:hover:text-pop-secondary cursor-pointer lg:duration-300 lg:transition-[color]"/>
                    </a>
                    <a href={socials.youtube} className="text-inherit" target="_blank" rel="noreferrer">
                      <AiOutlineYoutube className="text-2xl sm:text-3xl xl:text-4xl relative -top-[3px] sm:-top-[4px] font-thin lg:hover:text-pop-secondary cursor-pointer lg:duration-300 transition-[color]"/>
                    </a>
                    
                  </div>
                  
                </div>
                <div className="bar w-1/4 h-[1px] mx-auto bg-primary-700"></div>
                <div className="font-playfair text-primary-100 text-center relative font-black uppercase text-left w-full relative z-[15]">
                  <span className="block text-2xl sm:text-3xl md:text-4xl tracking-wider">
                    {data.site.siteMetadata.locationCity} // {pageData.secondaryCity}
                  </span>
                </div>
                <div className="bar w-1/4 h-[1px] mx-auto bg-primary-700"></div>
                <div className="font-playfair relative text-center text-primary-100 font-black uppercase w-full z-[15] relative">
                  <span className="block text-2xl sm:text-3xl md:text-4xl tracking-wider">
                    {pageData.number} 
                  </span>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </Layout>
    </>

  )
 ; 
}

export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        socials {
          ig
          linkedin
          youtube
        }
        pageData {
          contact {
            text
            primaryPinCode
            secondaryCity
            secondaryPinCode
            number
          }
        }
        locationCity
      }
    }
  }
`

export default ContactPage
