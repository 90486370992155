import "../styles/components/about.scss"

import * as React from "react"

import { BsCalendarDate, BsClock } from "react-icons/bs"

import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import work from "../data/pages/work.json"

const WorkPage = ({data}) => {

  const images = data.allFile.nodes
  const workCount = Object.keys(work).length
  const [displayListCue, setDisplayListCue] = React.useState(false)

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setTimeout(() => setDisplayListCue(true), 300)
    }
  }, [])

  return (
    <>
      <Layout>
        <Seo title="Work" page="work" description="A curated collection of my work through the years."/>
          <div className="w-full min-h-screen-fill-content into relative pt-20 py-20">
            <div className="w-full sm:w-10/12 lg:w-3/4 mx-auto">
              <div className="flex flex-col flex-1">
                {
                  Object.keys(work).map((name, index) => {
                    const project = work[name] ; 
                    const ytLink = project.links[0].url
                    const childImageSharp = images.filter((image) => image.name === name)[0].childImageSharp
                    return (
                      <div className={`flex ${index === 0 ? `` : `pt-20`} ${ displayListCue ? `opacity-1 translate-x-0` : `opacity-0 ${index % 2 === 0 ? `-translate-x-[15px]` : ` translate-x-[15px]`}` }`} key={`${index}-work`} id={`work-${index}`} style={{transition: `0.3s opacity ${ index*.075}s ease, 0.3s transform ${index*.075}s ease`}}>
                        <div className={"hidden lg:flex md:grow-[5] lg:flex-1"}>
                          <GatsbyImage image={childImageSharp.gatsbyImageData} objectFit="contain" objectPosition="0% 0%"/>
                        </div>

                        <div className="flex flex-1 md:grow-[5] flex-col lg:grow px-6">
                          <div className={'flex lg:hidden h-60 sm:h-72  bg-no-repeat bg-cover'}>
                            <GatsbyImage 
                              image={childImageSharp.gatsbyImageData} 
                              fluid={childImageSharp.fluid}
                              objectFit="contain"
                            />
                          </div>
                          <div className="info flex flex-col relative">
                            <span className="text-center mt-2 lg:mt-0 lg:text-left font-playfair font-black text-slate-400 text-base">
                              <a className="no-underline text-inherit font-playfair font-black text-slate-400 text-sm sm:text-base" href={`#work-${index+1}`}>
                                {index + 1} / {workCount}
                              </a>
                            </span>
                            <a href={ytLink} className="no-underline">
                              <span className="text-pop hover:text-pop-secondary duration-[300ms] text-center block lg:text-left font-playfair font-black text-2xl sm:text-3xl lg:text-3xl">{project.title}</span>
                            </a>
                            <div className="flex flex-row mt-3 justify-center lg:justify-start flex-wrap font-bold gap-y-1 gap-x-3">
                              {
                                Object.keys(project.meta).map((key) => {
                                  if(key === "time" && project.meta.time !== undefined) {
                                    return (
                                      <div className="flex flex-row items-center text-slate-500">
                                        <BsClock className="text-sm"/>
                                        <span className="text-xs lg:text-sm font-raleway pl-1">{project.meta.time}</span>
                                      </div>
                                    )
                                  } else if(key === "year" && project.meta.year !== undefined) {
                                    return (
                                      <div className="flex flex-row items-center text-slate-500">
                                        <BsCalendarDate className="text-sm"/>
                                        <span className="text-xs lg:text-sm font-raleway pl-1">{project.meta.year}</span>
                                      </div>
                                    )
                                  } else {
                                    return <></>
                                  }
                                })
                              }
                            </div>
                            <span className="font-raleway desc-text text-left mt-3 text-primary-100">{project.desc}</span>
                          </div>
                          <div className="flex flex-row mt-3 md:mt-6 justify-center flex-wrap mb-3">
                            {
                              project.links.map((link,index) => {
                                return(
                                  <div className="flex flex-row items-center text-slate-400">
                                    <a 
                                      className={
                                        `font-playfair text-xs font-bold pl-1 text-inherit hover:text-pop-secondary duration-300 cursor-pointer ` +
                                        `transition-colors tracking-wide decoration-1 underline underline-offset-[6px] hover:underline ` +
                                        `${index > 0 ? `ml-2`: ``}`
                                      }
                                      target="_blank"
                                      href={link.url} rel="noreferrer"
                                    >
                                      {link.text}
                                    </a>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
      </Layout>
    </>

  )
 ; 
}

export const query = graphql`
  query WorkQuery {
    allFile(filter: {relativeDirectory: {eq: "work"}}) {
      nodes {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
        }
        name
      }
    }
  }
`
export default WorkPage
