import "../styles/components/plyr.css"
import "../styles/pages/showreel.scss"

import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ShowreelPage = () => {

    const mobilePlayer = React.createRef(false) ;
    const webPlayer = React.createRef(false) ; 

    React.useEffect(() => {
        if(typeof(document) !== "undefined" && typeof(window) !== "undefined") {
            if(mobilePlayer.current) {
                const Plyr = require('plyr')
                const p = new Plyr("#mobilePlayer", {
                    title:"Showreel 2023",
                    debug: false,
                    provider:'youtube'
                })
    
                p.source = {
                    type: 'video',
                    sources: [
                        {
                            src: 'https://www.youtube.com/watch?v=eWJld7wvwnM',
                            provider:'youtube'
                        },
                    ],
                }
            }
            if(webPlayer.current) {
                const Plyr = require('plyr')
                const p = new Plyr("#webPlayer", {
                    title:"Showreel 2023",
                    debug: false,
                    provider:'youtube'
                })
    
                p.source = {
                    type: 'video',
                    sources: [
                        {
                        src: 'https://www.youtube.com/watch?v=eWJld7wvwnM',
                        provider:'youtube'
                        },
                    ],
                }
            }            
        }
        
    })

    return (
        <>
            <Layout>
                <Seo title="Showreel 2023" page="showreel" description="A showreel of the work I did in the year 2023."/>
                <div className="w-full min-h-screen-fill-content into relative pt-20 py-20">
                    <div className="block md:hidden plyr-container absolute top-1/2 left-1/2 px-6 w-full -translate-x-1/2 -translate-y-1/2">
                        <div ref={mobilePlayer}>
                            <video
                                controls
                                crossOrigin
                                playsInline
                                id="mobilePlayer"
                            >
                            </video>
                        </div>
                        <span className="text-pop uppercase duration-[300ms] text-center block font-playfair font-black text-2xl sm:text-3xl lg:text-3xl mt-6 md:mt-8">Showreel 2023</span>
                    </div>
                    <div className="w-full px-6 sm:w-10/12 lg:w-3/4 md:px-0 mx-auto hidden md:block">
                        <div className="w-full" ref={webPlayer} >
                            <video
                                controls
                                crossOrigin
                                playsInline
                                id="webPlayer"
                            >
                            </video>
                        </div>
                        <span className="text-pop uppercase duration-[300ms] text-center block font-playfair font-black text-2xl sm:text-3xl lg:text-3xl  mt-6 md:mt-8">Showreel 2023</span>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default ShowreelPage
