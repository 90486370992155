import "../styles/hamburger.css"
import "../styles/pages/stills.scss"
import "../styles/components/skeleton.scss"

import * as React from "react"

import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs"
import { graphql, useStaticQuery } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Minimasonry from 'minimasonry'
import Seo from "../components/seo"
import Spinner from "../components/spinner"

function after(count, f) {
  let noOfCalls = 0;
  return function (...rest) {
    noOfCalls = noOfCalls + 1;
    if (count === noOfCalls) {
      f(...rest);
    }
  };
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

const WorkPage = () => {

  let grid ; 

  const query = useStaticQuery(graphql`
      query MyQuery {
        allFile(filter: {relativeDirectory: {eq: "stills"}}) {
          nodes {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      } 
  `)

  let nodes = query.allFile.nodes

  const [isLoading,setIsLoading] = React.useState(true)
  const [inPreviewMode, setPreviewMode] = React.useState(false)
  const [previewIndex, setPreviewIndex] = React.useState(0)
  const [isGridSupported, setIsGridSupported] = React.useState(true)

  let timeout = false 

  const resizeHandler = () => {
    // clear the timeout
    if(timeout) clearTimeout(timeout);

    // start timing for event "completion"
    timeout = setTimeout(() => {
      //grid.destroy()
      grid = new Minimasonry({
        container:'#grid',
        surroundingGutter:false,
        gutterX: 12,
        gutterY: 12, 
        ultimateGutter: 24,
        baseWidth: window.innerWidth < 400 ? parseInt(window.innerWidth) - 48 : 350
      })
    }, 500);
  }

  const previewImage = (index) => {
    setPreviewMode(true)
    setPreviewIndex(index)
  }

  const moveToImage = (index) => {
    setPreviewIndex(index)
  }

  const untogglePreview = () => {
    setPreviewMode(false)
  }

  React.useEffect(() => {
    if (typeof(window) !== 'undefined') {

      shuffleArray(nodes)

      const isGridSupportedOnDevice = window && window.CSS && window.CSS.supports ? window.CSS.supports("display","grid") : false
      if(!isGridSupportedOnDevice) {
        window.addEventListener('resize', resizeHandler)
      }


      setIsGridSupported(isGridSupportedOnDevice)   
            
      return () => {
        if (!isGridSupportedOnDevice) window.removeEventListener('resize', resizeHandler); 
      }

      
    }
  },[])

  const onImageLoad = after(Math.floor(nodes.length/4), () => {
    if(typeof(window) !== 'undefined') {
      if (!isGridSupported) {
        resizeHandler();
        window.setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      } else {
        setTimeout(() => setIsLoading(false), 300)
      }
      
    } 
  })

  return (
    <>
      <Layout>
        <Seo title="Stills" page="stills" decription="A presentation of photographs I've color graded to respresent the mood of the situation."/>
          <div className="w-full">
            <div className={`relative w-full min-h-screen-fill-content ${isLoading ? `` : ``}`}>
              <div className={
                `fixed w-full min-h-screen-fill-content max-w-screen-2xl mx-auto  ` + 
                ` duration-500 z-10 ${isLoading && !isGridSupported? `opacity-1` : `opacity-0 z-change-delay`}  bg-primary`
              }>
                <div className={
                  `absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2`
                }>
                  <Spinner color="pop-secondary"/>
                </div>
              </div>
              <div className={
                `fixed w-full h-screen-fill-content modal max-w-screen-2xl mx-auto ` + 
                ` duration-500 opacity-1 bg-primary/80 px-6 py-[4.75rem] md:py-20` + 
                `${inPreviewMode ? ` z-[10000] block` : ` -z-[10] hidden`}`
              }>
                <div className="hamburger-container absolute right-[1.5rem] top-[1.5rem]">
                  <div className="hamburger hamburger--slider is-active">
                    <button className="hamburger" type="button">
                      <span className="hamburger-box h-5" onClick={() => untogglePreview()}>
                        <span className="hamburger-inner"></span>
                      </span>
                    </button>
                  </div>
                </div>
                <GatsbyImage 
                  image={nodes[previewIndex].childImageSharp.gatsbyImageData}  
                  alt="Still" 
                  loading="eager"
                  objectFit="contain" 
                  className="mb-0 max-h-full max-w-full relative top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
                />
                <div className="top-1/2 left-[1.5rem] md:left-[1rem] -translate-y-1/2 absolute z-[100]">
                  <BsChevronCompactLeft className={`text-primary-100 text-4xl cursor-pointer md:hover:text-pop-secondary duration-300 opacity-${previewIndex === 0 ? `0 cursor-default` : `1 cursor-pointer`}`} onClick={() => {if(previewIndex === 0) return ; moveToImage(previewIndex - 1)}}/>
                </div>
                <div className="top-1/2 right-[1.5rem] md:right-[1rem] -translate-y-1/2 absolute z-[100]">
                  <BsChevronCompactRight className={`text-primary-100 text-4xl md:hover:text-pop-secondary duration-300 opacity-${previewIndex === nodes.length - 1 ? `0 cursor-default` : `1 cursor-pointer`}`}  onClick={() => {if(previewIndex === nodes.length - 1) return ; moveToImage(previewIndex + 1)}}/>
                </div>
              </div>

              {
                isLoading ? 
                  isGridSupported ? 
                    <div className="pt-20 pb-6 px-6 z-[10] 2xl:px-0 w-full min-h-screen-fill-content">
                      <div className="skeleton w-full bg-primary columns-1 md:columns-2 lg:columns-3 space-y-6 sm:space-y-5 lg:space-y-3">
                        {
                          ['h-64', 'h-72', 'h-96', 'h-64','h-96', 'h-64', 'h-72', 'h-96', 'h-64'].map((height,index) => {
                              return(
                                  <div className={`grid ${height} skeleton-element bg-primary-900`} key={`grid-skeleton-${index}`}>
                                  </div>
                              )
                          })
                        }
                      </div>
                    </div>
                  : 
                  ``
                :
                  ``
                }
                <div className={`pt-20 pb-6 px-6 2xl:px-0 max-w-screen-2xl relative z-[5] mx-auto into ${isLoading ? `h-0 !p-0 overflow-hidden`: `min-h-screen-fill-content`}`}>
                  {
                    isGridSupported ? 
                      <div className="min-h-screen-fill-content columns-1 md:columns-2 lg:columns-3 space-y-6 sm:space-y-5 lg:space-y-3">
                        {
                          nodes.map(({childImageSharp},index) => {
                              return(
                                  <div className="grid" key={`still-${index}`} onClick={() => previewImage(index)}>
                                      <GatsbyImage image={childImageSharp.gatsbyImageData} onLoad={() => onImageLoad()} alt="Still Image" loading="eager" className="mb-0 cursor-pointer"/>
                                  </div>
                              )
                          })
                        }
                      </div>
                    : 
                      <div id="grid">
                        {
                            nodes.map(({childImageSharp},index) => {
                                return(
                                    <div className="grid absolute" key={`still-${index}`} onClick={() => previewImage(index)}>
                                        <GatsbyImage image={childImageSharp.gatsbyImageData} onLoad={() => onImageLoad()} alt="Still Image" loading="eager" className="mb-0 cursor-pointer"/>
                                    </div>
                                )
                            })
                        }
                      </div>
                  }
                </div>
            </div>
          </div>
      </Layout>
    </>

  )
 ; 
}

export default WorkPage
