import "../styles/components/plyr.css"
import "../styles/pages/showreel.scss"

import * as React from "react"

import Layout from "../components/layout"
import { Link } from 'gatsby'
import Seo from "../components/seo"
import { TbMoodSad } from "react-icons/tb"

const NotFoundPage = () => {


    return (
        <>
            <Layout>
                <Seo title="Work" />
                <div className="w-full min-h-screen-fill-content into relative pt-20 py-20">
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 font-raleway text-primary-100 text-center">
                        <TbMoodSad className="text-[3rem] sm:text-[4.5rem] lg:text-[6rem] mx-auto block font-extralight text-red-error"/>
                        <span className="mt-10 block text-lg">Oops ... I couldn't find what you were looking for.</span>
                        <span className="mt-5 block">
                            But you could check out my 
                            <Link 
                                to="/work" 
                                className={`hover:text-pop-secondary duration-300 text-base mx-1 cursor-pointer decoration-1 underline underline-offset-[6px] hover:underline text-pop`}
                            >
                                work
                            </Link>
                            instead
                        </span>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default NotFoundPage
